import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Sentry 

import * as Sentry from "@sentry/angular-ivy";
import { BrowserTracing } from "@sentry/browser";

Sentry.init({
  dsn: environment.SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ["localhost", environment.SENTRY_URL, environment.cardboardURL, environment.contractURL, environment.resourcesURL, environment.crmURL],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
      unmask: [".swal2-popup", ".sentry-unmask", "[data-sentry-unmask]"],
    })
  ],
  replaysSessionSampleRate: 0.7,
  replaysOnErrorSampleRate: 1.0,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.7,
});


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
