<div mat-dialog-content [formGroup]="formGroup">
  <section class="mt-2 mb-3">
    <label class="label-control">Ingresa la razón por la cual estás eliminando esta factura:</label>
    <textarea class="form-control" formControlName="reason" rows="3" cdkFocusInitial></textarea>
  </section>
</div>

<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{'General_Cancel' | translate}}
  </button>

  <button mat-button color="primary" [disabled]="formGroup.invalid" [mat-dialog-close]="formGroup.value">
    {{'General_Delete' | translate}}
  </button>
</div>
