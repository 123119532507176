import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AddCompanyComponent } from "./components/company-registration/add-company/add-company.component";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import {
  FontAwesomeModule,
  FaIconLibrary,
} from "@fortawesome/angular-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { NgSelectModule } from "@ng-select/ng-select";
import {
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateParser,
} from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HomeComponent } from "./components/home/home.component";
import { FileUploadComponent } from "./components/addons/file-upload/file-upload.component";
import { DndUploadComponent } from "./components/addons/dnd-upload/dnd-upload.component";
import { DialogAddRepresentativeComponent } from "./components/dialogs/add-representative/add-representative/add-representative.component";
import { DialogCompanyHistoryComponent } from "./components/dialogs/dialog-company-history/dialog-company-history.component";
import { DialogTermsConditionsComponent } from "./components/dialogs/dialog-terms-conditions/dialog-terms-conditions.component";
import { ModalRepresentativeComponent } from "./components/dialogs/dialog-representative/modal-representative.component";
import { DialogPrivacyPolicyComponent } from "./components/dialogs/dialog-privacy-policy/dialog-privacy-policy.component";
import { AddRepresentativeComponent } from "./components/representatives/add-representative/add-representative.component";
import { IUsersService } from "./services/users/user";
import { ITermConditionsService } from "./services/terms-conditions/terms-conditions";
import { IAuthenticationService } from "./services/authentication/authentication";
import { ICompanyService } from "./services/company/company";
import { IRegisterService } from "./services/register/register";
import { IVatValidation } from "./services/vat-validation/vat-validation";
import { IPrivacyPolicyService } from "./services/privacy-policy/privacy-policy";
import { IVATService } from "./services/vat/vat";
import { IProjectService } from "./services/projects/project";
import { IFileUploadService } from "./services/file-upload/file-upload";
import { IHelperService } from "./services/helper/helper";
import { IIndustryService } from "./services/industry/industry";
import { ICountryService } from "./services/country/country";
import { UsersService } from "./services/users/users.service";
import { TermsConditionsService } from "./services/terms-conditions/terms-conditions.service";
import { AuthenticationService } from "./services/authentication/authentication.service";
import { CompanyService } from "./services/company/company.service";
import { PrivacyPolicyService } from "./services/privacy-policy/privacy-policy.service";
import { VATService } from "./services/vat/vat.service";
import { ProjectService } from "./services/projects/project.service";
import { FileUploadService } from "./services/file-upload/file-upload.service";
import { HelperService } from "./services/helper/helper.service";
import { IndustryService } from "./services/industry/industry.service";
import { CountryService } from "./services/country/country.service";
import { VatValidationService } from "./services/vat-validation/vat-validation.service";
import { RegisterService } from "./services/register/register.service";
import { MenuComponent } from "./components/sections/menu/menu/menu.component";
import { BreadcrumbComponent } from "./components/sections/menu/breadcrumb/breadcrumb.component";
import { FooterComponent } from "./components/sections/menu/footer/footer.component";
import { HeaderComponent } from "./components/sections/menu/header/header.component";
import { SidebarComponent } from "./components/sections/menu/sidebar/sidebar.component";
import { ViewTitleComponent } from "./components/sections/menu/view-title/view-title.component";
import { CompanyEmailValidationComponent } from "./components/company-registration/company-email-validation/company-email-validation.component";
import { ITranslationService } from "./services/translations/translation";
import { TranslationService } from "./services/translations/translation.service";
import { AgmCoreModule } from "@agm/core";
import { CompanyDocumentUploadComponent } from "./components/company-document-upload/company-document-upload.component";
import { ValidationSummaryComponent } from "./components/addons/validation-summary/validation-summary.component";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from "ngx-ui-loader";
import { CompanyCreatedComponent } from "./components/company-created/company-created.component";
import { AddLSCCompanyComponent } from "./components/company-registration/LCS-registration/add-company/add-company.component";
import { LandingPageComponent } from "./components/home/landing-page/landing-page.component";
import { LanguageInterceptor } from "./interceptors/language.interceptor";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { HeaderAreaComponent } from "./components/sections/header-area/header-area.component";
import { LandingPageContactFormComponent } from "./components/home/landing-page-contact-form/landing-page-contact-form.component";
import { NgxTranslateDebugParser } from "ngx-translate-debug";
import { KeyboardShortcutsModule } from "ng-keyboard-shortcuts";
import { LandingPageFAQComponent } from "./components/home/landing-page-faq/landing-page-faq.component";
import { LandingPageTestimonialsComponent } from "./components/home/landing-page-testimonials/landing-page-testimonials.component";
import { LandingPageMoreInfoComponent } from "./components/home/landing-page-more-info/landing-page-more-info.component";
import { LandingPageMoreCPAComponent } from "./components/home/landing-page-more-cpa/landing-page-more-cpa.component";
import { LandingPageCallToActionComponent } from "./components/home/landing-page-call-to-action/landing-page-call-to-action.component";
import { LandingPageContactSectionComponent } from "./components/home/landing-page-contact-section/landing-page-contact-section.component";
import { LandingPageServicesComponent } from "./components/home/landing-page-services/landing-page-services.component";
import { SafePipe } from "./pipes/safe.pipe";
import { OnlyNumbers } from "./pipes/onlyNumbers.pipe";
import { ICardboardService } from "./services/cardboard/cardboard.interface";
import { CardboardService } from "./services/cardboard/cardboard.service";
import { EmailUnsubscribeComponent } from "./components/pages/email-unsubscribe/email-unsubscribe.component";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { TermsAndConditionsComponent } from "./components/pages/terms-and-conditions/terms-and-conditions.component";
import { AboutUsComponent } from "./components/pages/about-us/about-us.component";
import { SmartecFileUploadComponent } from "./components/addons/smartec-file-upload/smartec-file-upload.component";
import { RepresentativeSignatureComponent } from "./components/signature/representative-signature/representative-signature.component";
import { RepresentativeHasSignedComponent } from "./components/signature/representative-has-signed/representative-has-signed.component";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { CountryPhoneSelectorComponent } from './components/addons/country-phone-selector/country-phone-selector.component';
import { DisplayContractSignedComponent } from './components/pages/display-contract-signed/display-contract-signed.component';
// Sentry
import { APP_INITIALIZER, ErrorHandler} from "@angular/core";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import { RegisterPaymentComponent } from './components/pages/register-payment/register-payment.component';
import { DeleteInvoicesComponent } from './components/dialogs/delete-invoices/delete-invoices.component';

@NgModule({
  declarations: [
    AppComponent,
    AddCompanyComponent,
    HomeComponent,
    FileUploadComponent,
    DndUploadComponent,
    DialogAddRepresentativeComponent,
    DialogCompanyHistoryComponent,
    DialogTermsConditionsComponent,
    ModalRepresentativeComponent,
    DialogPrivacyPolicyComponent,
    DndUploadComponent,
    FileUploadComponent,
    AddRepresentativeComponent,
    MenuComponent,
    BreadcrumbComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    ViewTitleComponent,
    CompanyEmailValidationComponent,
    CompanyDocumentUploadComponent,
    ValidationSummaryComponent,
    CompanyCreatedComponent,
    AddLSCCompanyComponent,
    LandingPageComponent,
    HeaderAreaComponent,
    LandingPageContactFormComponent,
    LandingPageFAQComponent,
    LandingPageTestimonialsComponent,
    LandingPageMoreInfoComponent,
    LandingPageMoreCPAComponent,
    LandingPageCallToActionComponent,
    LandingPageContactSectionComponent,
    LandingPageServicesComponent,
    SafePipe,
    OnlyNumbers,
    EmailUnsubscribeComponent,
    FaqComponent,
    TermsAndConditionsComponent,
    AboutUsComponent,
    SmartecFileUploadComponent,
    RepresentativeSignatureComponent,
    RepresentativeHasSignedComponent,
    CountryPhoneSelectorComponent,
    DisplayContractSignedComponent,
    RegisterPaymentComponent,
    DeleteInvoicesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    NgSelectModule,
    MatCheckboxModule,
    MatTableModule,
    MatInputModule,
    MatSliderModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatDialogModule,
    MatTooltipModule,
    MatButtonModule,
    MatSelectModule,
    MatCardModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatStepperModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      parser: { provide: TranslateParser, useClass: NgxTranslateDebugParser },
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCQSo14pdjqVm7Vj3IRKRA_CVxNp9DE_fU",
      libraries: ["places"],
    }),
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
    }),
    KeyboardShortcutsModule.forRoot(),
    AccordionModule.forRoot(),
    MatDividerModule,
    MatListModule,
  ],
  providers: [
    // Sentry at first position
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    // Module services
    Title,
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: IUsersService, useClass: UsersService },
    { provide: ITermConditionsService, useClass: TermsConditionsService },
    { provide: IAuthenticationService, useClass: AuthenticationService },
    { provide: ICompanyService, useClass: CompanyService },
    { provide: IRegisterService, useClass: RegisterService },
    { provide: IVatValidation, useClass: VatValidationService },
    { provide: ICountryService, useClass: CountryService },
    { provide: IIndustryService, useClass: IndustryService },
    { provide: IHelperService, useClass: HelperService },
    { provide: IFileUploadService, useClass: FileUploadService },
    { provide: IProjectService, useClass: ProjectService },
    { provide: IVATService, useClass: VATService },
    { provide: IPrivacyPolicyService, useClass: PrivacyPolicyService },
    { provide: ITranslationService, useClass: TranslationService },
    { provide: ICardboardService, useClass: CardboardService },
    { provide: MAT_DATE_LOCALE, useValue: "es-ES" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}

// AOT compilation support
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${environment.resourcesURL}/resources/`
  );
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return "missing";
  }
}
