import { IUserRegistration } from "src/app/models/users/IUserRegistration";
import { PaginationService } from "../pagination/pagination.service";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { retry, catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { throwError, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import Swal from "sweetalert2";
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  private _apiURL: String = `${environment.crmURL}/api/users/`;

  constructor(
    private _http: HttpClient,
    private _paginationService: PaginationService,
    private _translateService: TranslateService
  ) {}

  getUsers() {
    return this._http
      .get<IUserRegistration[]>(
        `${this._apiURL}GetUsers?page=${this._paginationService.page}&pageCount=${this._paginationService.pageCount}`
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getAllUsers() {
    return this._http
      .get<IUserRegistration[]>(`${this._apiURL}GetAllUsers`)
      .pipe(retry(1), catchError(this.handleError));
  }

  saveUser(user: IUserRegistration): Observable<any> {
    return this._http
      .post<any>(`${this._apiURL}SaveUser`, user)
      .pipe(retry(0), catchError(this.handleError));
  }

  deleteUser(id: String): Observable<any> {
    const user = { Id: id };

    return this._http
      .post<any>(`${this._apiURL}DeleteUser`, user)
      .pipe(retry(0), catchError(this.handleError));
  }

  validateToken(token: String): Observable<any> {
    return this._http
      .get<any>(`${this._apiURL}Validate?token=${token}`)
      .pipe(retry(1), catchError(this.handleError));
  }

  decryptUserInfo(user: IUserRegistration) {
    return this._http
      .post<any>(`${this._apiURL}DecryptUserInfo`, user)
      .pipe(retry(0), catchError(this.handleError));
  }

  handleError(error: any) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      var id = Sentry.captureException(error.error);
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      var id = Sentry.captureException(error);
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nTraceID: ${id}\nMessage: ${error.message}`;
    }

    Swal.fire({
      title: `${this._translateService.instant("General_Error")}`,
      text: errorMessage,
      icon: "error",
    });

    return throwError(errorMessage);
  }
}
