import { FileUploadItem } from "src/app/models/files/fileUploadItem";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { map, catchError, retry } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { throwError, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import Swal from "sweetalert2";
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: "root",
})
export class FileUploadService {
  private apiURL = `${environment.crmURL}/api/companies/`;
  private apiURLDocuments = `${environment.crmURL}/api/documents/`;

  constructor(private http: HttpClient, private translate: TranslateService) {}

  uploadFiles(filesToUpload: FileUploadItem, id: string) {
    const formData: FormData = new FormData();
    const currentFile: FileUploadItem = filesToUpload;

    formData.append("file", currentFile.File, currentFile.Name);
    formData.append("fileDateName", currentFile.DateName.toString());
    formData.append("id", id);

    return this.http
      .post<any>(`${this.apiURL}uploadFilesToCompany`, formData, {
        reportProgress: true,
        observe: "events",
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              filesToUpload.Progress = progress;
              return { status: "progress", message: progress };

            case HttpEventType.Response:
              return event.body;
            default:
              Sentry.captureMessage(`Unhandled event: ${event.type}`);
              return `Unhandled event: ${event.type}`;
          }
        })
      );
  }

  uploadUnassignedDocuments(documents: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.apiURLDocuments}UploadUnassignedDocument`,
        documents,
        {}
      )
      .pipe(retry(0), catchError(this.handleError));
  }

  handleError(error: any) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      Sentry.captureException(error.error);
      errorMessage = error.error.message;
    } else {
      Sentry.captureException(error);
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    Swal.fire({
      title: `${this.translate.instant("General_Error")}`,
      text: errorMessage,
      icon: "error",
    });

    return throwError(errorMessage);
  }
}
