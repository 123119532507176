<div class="header-area header-business" style="padding-bottom: 10px;">
  <div class="business-bg-img wow animate__ animate__zoomIn animated" data-parallax='{"x": 220, "y": 100}'
    style="background-image:url(/assets/images/illustrations/box.svg);">
  </div>

  <div class="business-bg-img-02 wow animate__ animate__zoomIn animated"
    style="background-image: url(&quot;/assets/images/background/bg_header.png&quot;); visibility: visible; animation-name: zoomIn;">
  </div>

  <div class="shape-02"></div>

  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="header-inner">
          <h1 class="title">
            {{'SC_CartelPageTitle' | translate}}
          </h1>

          <p class="text-justify" [innerHTML]="'SC_CartelPageSubtitle' | translate"></p>

          <div class="btn-wrapper padding-top-30">
            <a [routerLink]="['/register/']" class="boxed-btn btn-business">
              {{'SC_CallToAction' | translate}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
