import { DialogTermsConditionsComponent } from "src/app/components/dialogs/dialog-terms-conditions/dialog-terms-conditions.component";
import { DialogPrivacyPolicyComponent } from "src/app/components/dialogs/dialog-privacy-policy/dialog-privacy-policy.component";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  AbstractControl,
} from "@angular/forms";
import { IRegisterAuthorities } from "src/app/models/parameters/IRegisterAuthorities";
import { IVatValidationObject } from "src/app/models/company/IVatValidationObject";
import { ICardboardService } from "src/app/services/cardboard/cardboard.interface";
import { ViewTitleService } from "src/app/services/viewTitle/view-title.service";
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { INationality } from "src/app/models/parameters/INationality";
import { ICountryService } from "src/app/services/country/country";
import { environment } from "src/environments/environment";
import { Router, ActivatedRoute } from "@angular/router";
import { NgSelectComponent } from "@ng-select/ng-select";
import { IVATService } from "src/app/services/vat/vat";
import { TranslateService } from "@ngx-translate/core";
import { MatStepper } from "@angular/material/stepper";
import { MatDialog } from "@angular/material/dialog";
import Swal from "sweetalert2";

@Component({
  selector: "lsc-add-company",
  templateUrl: "./add-company.component.html",
  styleUrls: ["./add-company.component.scss"],
})
export class AddLSCCompanyComponent implements OnInit, OnDestroy {
  @ViewChild("inputFile") inputFile!: ElementRef;
  @ViewChild("stepper") private stepper: MatStepper;
  @ViewChild("selectLegalForms") selectLegalForms;

  public companyFormGroup: FormGroup;
  public uploadContractFormGroup: FormGroup;
  public SeparateDialCode = false;
  public Nationalities: INationality[];
  public vatValidationObject: IVatValidationObject;
  public Authorities: IRegisterAuthorities[];
  public userAccountsLimit: number = 15;
  public file: any;
  public updateFile: any;
  public crmAPI = environment.crmURL;
  public signature: any;
  public tradeRegisterExcerpt: any = undefined;
  public completed: any = {
    companyInformation: false,
    cardboardRepresentatives: false,
    contractSignature: false,
  };
  public countries: any[] = [];
  public vat: string = "";
  public legalForms: any[] = [];
  public company: any = {};

  public titles: any[] = [
    "General_Mr",
    "General_Ms",
    "General_Dr",
    "General_Dra",
  ];
  public provinces: any[] = [];
  public businessTitles: string[] = [];
  public phoneCountries: any[] = [
    // {
    //   name: "General_Belgium",
    //   code: "+32",
    //   flag: "be",
    // },
    // {
    //   name: "General_France",
    //   code: "+33",
    //   flag: "fr",
    // },
    // {
    //   name: "General_Germany",
    //   code: "+49",
    //   flag: "de",
    // },
    // {
    //   name: "General_Italy",
    //   code: "+39",
    //   flag: "it",
    // },
    // {
    //   name: "General_Luxembourg",
    //   code: "+352",
    //   flag: "lu",
    // },
    {
      name: "General_Spain",
      code: "+34",
      flag: "es",
    },
    // {
    //   name: "General_Switzerland",
    //   code: "+41",
    //   flag: "ch",
    // },
  ];

  get ContactName(): AbstractControl {
    return this.companyFormGroup.get(["contact", "name"]);
  }

  get ContactLastName(): AbstractControl {
    return this.companyFormGroup.get(["contact", "lastName"]);
  }

  get ContactTitle(): AbstractControl {
    return this.companyFormGroup.get(["contact", "title"]);
  }

  get ContactEmail(): AbstractControl {
    return this.companyFormGroup.get(["contact", "email"]);
  }

  get ContactPhone(): AbstractControl {
    return this.companyFormGroup.get(["contact", "phone"]);
  }

  get ContactCountryPhone(): AbstractControl {
    return this.companyFormGroup.get(["contact", "countryPhone"]);
  }

  @ViewChild("select") select: NgSelectComponent;
  private onScroll = (event: any) => {
    if (this.select && this.select.isOpen) {
      const isScrollingInScrollHost =
        (event.target.className as string).indexOf("ng-dropdown-panel-items") >
        -1;
      if (isScrollingInScrollHost) {
        return;
      }
      this.select.close();
    }
  };

  private setCompany: boolean = false;
  public vatduplicated: string = "";

  constructor(
    private cardboardService: ICardboardService,
    private viewTitleService: ViewTitleService,
    private countryService: ICountryService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private vatService: IVATService,
    private _dialog: MatDialog,
    private _router: Router
  ) {
    this.companyFormGroup = this.formBuilder.group({
      id: 0,
      name: ["", [Validators.required, Validators.maxLength(100)]],
      vat: ["", [Validators.required, Validators.minLength(8)]],
      vatValidation: [true, Validators.requiredTrue],
      legalForm: ["", Validators.required],
      comment: [""],
      contact: this.formBuilder.group({
        id: 0,
        name: ["", Validators.required],
        lastName: ["", Validators.required],
        title: ["", Validators.required],
        email: [
          "",
          [
            Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
            Validators.required,
          ],
        ],
        countryPhone: ["+34", Validators.required],
        phone: [
          "",
          [Validators.required, Validators.pattern("^[- +()0-9]{7,}")],
        ],
      }),
      country: ["", Validators.required],
      street: ["", Validators.required],
      number: [""],
      city: ["", Validators.required],
      state: ["", Validators.required],
      postalCode: ["", Validators.required],
      cardboardUsers: this.formBuilder.array([]),
      termsConditions: [false, Validators.requiredTrue],
      privacyPolicy: [false, Validators.requiredTrue],
    });

    this.uploadContractFormGroup = this.formBuilder.group({
      file: [""],
      cardboardRepresentatives: this.formBuilder.array([]),
    });
  }

  ngOnInit() {
    window.addEventListener("scroll", this.onScroll, true);

    this.setBusinessTitles();
    this.getLegalForms();
    this.getStatesByCountry();

    this.translate.stream("General_Company").subscribe((value: string) => {
      this.companyFormGroup.patchValue({
        country: this.translate.instant("General_Spain"),
      });

      this.companyFormGroup.get("country").disable({ onlySelf: true });

      this.viewTitleService.emitChange({
        title: `${this.translate.instant(
          "General_Company"
        )} | CDC Cartel Damage Claims`,
        breadCrumbsItems: [],
      });

      this.initVat();

      this.activatedRoute.params.subscribe((value: any) => {
        this.companyFormGroup.patchValue({
          id: value.id ?? 0,
          vat: value.vat ?? "",
        });

        if (!this.setCompany) {
          this.setCompanyData();
        }
      });
    });

    this.countries = [
      {
        name: "Spain",
        id: "5eb42ac3a4fcca596c4ccc28", // crm_company_id
      },
    ];
  }

  ngOnDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  }

  setBusinessTitles(): void {
    this.cardboardService.getRepresentativeTitlesByProject('63aabe251e00c4ecdb9da718').subscribe((response: any) => {
      this.businessTitles = response;
    })
  }

  getLegalForms(): void {
    this.cardboardService
      .getLegalFormsByCountryISO2("es")
      .subscribe((response: any) => {
        if (response) {
          this.legalForms = response;
        }
      });
  }

  getStatesByCountry(): void {
    this.cardboardService
      .getStatesByCountry("Spain")
      .subscribe((response: any) => {
        if (response) {
          this.provinces = response;
        }
      });
  }

  setCompanyData(): void {
    const company = Object.assign({}, this.companyFormGroup.value);

    if (company?.id && company?.vat) {
      this.cardboardService
        .getCardboardCompanyByVAT(company.vat)
        .subscribe((response: any) => {
          if (!response) {
            this._router.navigate(["/register/"]);
            return;
          }

          this.company = response;
          this.vat = response.vat;

          this.companyFormGroup.patchValue({
            id: response.id,
            name: response.name,
            vat: response.vat,
            legalForm: response.legalForm,
            comment: response.comment,
            country: response.country,
            city: response.city,
            state: response.state,
            street: response.street,
            number: response.number,
            postalCode: response.postalCode,
            termsConditions: true,
            privacyPolicy: true,
          });

          if (response.name) {
            this.companyFormGroup.get("name").disable({ onlySelf: true });
          }

          if (response.vat) {
            this.companyFormGroup.get("vat").disable({ onlySelf: true });
          }

          if (response.cardboardContacts?.length > 0) {
            const contact = {
              id: response.cardboardContacts[0]?.id,
              name: response.cardboardContacts[0]?.name,
              lastName: response.cardboardContacts[0]?.lastName,
              title: response.cardboardContacts[0]?.title,
              email: response.cardboardContacts[0]?.email,
              phone: response.cardboardContacts[0]?.phone,
              countryPhone: response.cardboardContacts[0]?.countryPhone,
            };

            this.companyFormGroup.patchValue({
              contact: contact,
            });
          }

          this._router.navigate([`/register/${company.vat}/${response.id}`]);

          if (response.cardboardUsers?.length) {
            while (this.cardboardUsers.length !== 0) {
              this.cardboardUsers.removeAt(0);
            }

            response.cardboardUsers.map((value: any) => {
              this.cardboardUsers.push(
                this.formBuilder.group({
                  id: value.id,
                  name: [value.name, Validators.required],
                  lastName: [value.lastName, Validators.required],
                  title: [value.title, Validators.required],
                  email: [
                    value.email,
                    [
                      Validators.required,
                      Validators.pattern(
                        "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"
                      ),
                    ],
                  ],
                })
              );
            });
          }

          if (
            !this.companyFormGroup.invalid &&
            this.cardboardUsers?.length > 0
          ) {
            this.completed.companyInformation = true;
            this.stepper.selected.completed = true;
            this.stepper.next();
          }

          if (response.cardboardRepresentatives?.length) {
            while (this.cardboardRepresentatives.length !== 0) {
              this.removeRepresentative(0);
            }

            response.cardboardRepresentatives.map((value: any) => {
              this.cardboardRepresentatives.push(
                this.formBuilder.group({
                  id: value.id,
                  name: [value.name, Validators.required],
                  lastName: [value.lastName, Validators.required],
                  title: [value.title, Validators.required],
                  businessTitle: [value.businessTitle, Validators.required],
                  dni: [value.dni, Validators.required],
                  dniValidation: [true, Validators.requiredTrue],
                  email: [
                    value.email,
                    [
                      Validators.required,
                      Validators.pattern(
                        "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"
                      ),
                    ],
                  ],
                  phone: [value.phone],
                  countryPhone: [value.countryPhone],
                })
              );
            });

            // if (!response.signature) {
            //   this.createNDA();
            // }
          }

          if (response.document) {
            this.tradeRegisterExcerpt = {
              fullName: response.document.document,
              displayName: response.document.fileName,
            };
          }

          this.signature = response.signature;

          if (this.cardboardRepresentatives?.length > 0 && response.document) {
            this.completed.cardboardRepresentatives = true;
            this.stepper.selected.completed = true;
            this.stepper.next();

            if (this.completed.cardboardRepresentatives) {
              this.companyFormGroup?.get("name")?.disable();
              this.companyFormGroup?.get("vat")?.disable();
              this.companyFormGroup?.get("legalForm")?.disable();
              this.companyFormGroup?.get("comment")?.disable();
              this.companyFormGroup?.get("country")?.disable();
              this.companyFormGroup?.get("city")?.disable();
              this.companyFormGroup?.get("state")?.disable();
              this.companyFormGroup?.get("street")?.disable();
              this.companyFormGroup?.get("number")?.disable();
              this.companyFormGroup?.get("postalCode")?.disable();
              this.companyFormGroup?.get("contact")?.disable();
              this.companyFormGroup?.get("termsConditions")?.disable();
              this.companyFormGroup?.get("privacyPolicy")?.disable();
              this.companyFormGroup?.get("countryPhone")?.disable();
            }
          }
        });
    } else {
      while (this.cardboardUsers.length !== 0) {
        this.cardboardUsers.removeAt(0);
      }

      this.addUserToList();
    }

    this.setCompany = true;
  }

  initVat() {
    this.vatValidationObject = {
      valid: null,
      company_address: "",
      company_name: "",
      country_code: "",
      database: "",
      format_valid: false,
      query: "",
      vat_number: "",
    };
  }

  hasOneDigit(str: string) {
    const oneDigitPattern = new RegExp("(?=.*?[0-9])");
    return oneDigitPattern.test(str);
  }

  getNationalities() {
    this.countryService.getAllNationalities().subscribe((response: any) => {
      this.Nationalities = response;
    });
  }

  validateVatNumber() {
    const vat = this.companyFormGroup.controls["vat"].value;

    if (!vat) {
      return;
    }

    const reVAT = new RegExp(
      "^(AE[0-9]{8}|ATU[0-9]{8}|BE[0-9]{10}|BG[0-9]{9,10}|CY[0-9]{8}[A-Z]|CZ[0-9]{8,10}|DE[0-9]{9}|DK[0-9]{8}|EE[0-9]{9}|EL|GR[0-9]{9}|ES[0-9A-Z][0-9]{7}[0-9A-Z]|FI[0-9]{8}|FR[0-9,A-H,J-N,P-Z]{2}[0-9]{9}|GB[0-9]{9}|HR[0-9]{11}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|LV[0-9]{11}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9,10}|RO[0-9]{2,10}|SE[0-9]{12}|SI[0-9]{8}|SK[0-9]{10}|(CH|CHE)(d{6}|d{9})(MWST|TVA|IVA))$"
    );
    const isVAT = reVAT.test(vat);
    if (isVAT) {
      this.vatValidationObject.valid = true;
      return;
      //Temporaly disable endoint validation
      // this.vatService.getVatByNumber(vat).subscribe((response: any) => {
      //   if (response.status === 200) {
      //     if (!response.data) {
      //       this.vatValidation.validateVAT(vat).subscribe((vatLayer: any) => {
      //         this.vatValidationObject = vatLayer;

      //         if (vatLayer.valid === true) {
      //           this.saveValidVAT(vatLayer);
      //         }
      //       });
      //       return;
      //     }

      //     this.vatValidationObject = {
      //       valid: true,
      //       format_valid: true,
      //       country_code: response.data.countryCode,
      //       vat_number: response.data.vatNumber,
      //       company_name: response.data.companyName,
      //       company_address: response.data.companyAddress,
      //       database: "",
      //       query: "",
      //     };
      //   } else {
      //     Swal.fire({
      //       title: `${this.translate.instant("General_Error")}`,
      //       text: response.errors.join(","),
      //       icon: "error",
      //     });
      //   }
      // });
    } else {
      this.vatValidationObject.valid = false;
      return;
    }
  }

  saveValidVAT(vat: any) {
    const validVat = {
      companyName: vat.company_name,
      companyAddress: vat.company_address,
      vatNumber: vat.vat_number,
      countryCode: vat.country_code,
    };

    this.vatService.saveVat(validVat).subscribe((response: any) => {
      if (response.status === 200) {
      } else {
        Swal.fire({
          title: `${this.translate.instant("General_Error")}`,
          text: response.errors.join(","),
          icon: "error",
        });
      }
    });
  }

  smoothScroll(): void {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;

      if (pos > 0) {
        window.scrollTo(10, pos - 10); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  onSubmit(): void {
    if (
      this.cardboardUsers.length < 1 ||
      !this.cardboardUsers.value[0]?.name ||
      !this.cardboardUsers.value[0]?.lastName ||
      !this.cardboardUsers.value[0]?.email
    ) {
      Swal.fire({
        title: `${this.translate.instant("SCALE_MissingDataTitle")}`,
        text: `${this.translate.instant("SCALE_MissingUserDetails")}`,
        icon: "warning",
        timer: 4000,
      });

      return;
    }

    if (this.companyFormGroup.invalid) {
      return;
    }

    const company = Object.assign({}, this.companyFormGroup.value);
    company.country = "Spain";
    company.cardboardContacts = [company.contact];

    if (!company.id) {
      this.cardboardService
        .companyRegistration(company)
        .subscribe((companyId) => {
          if (companyId) {
            Swal.fire({
              title: `${this.translate.instant("General_Created")}`,
              text: `${this.translate.instant("CRM_Company_Sucess_Created")}`,
              icon: "success",
              timer: 3000,
            }).then(() => {
              this._router.navigate([`/register/${company.vat}/${companyId}`]);

              this.completed.companyInformation = true;
              this.stepper.selected.completed = true;
              this.stepper.next();
            });
          }
        });
    } else {
      this.cardboardService
        .updateCardboardCompany(company)
        .subscribe((updated: boolean) => {
          if (updated) {
            Swal.fire({
              title: `${this.translate.instant("General_Updated")}`,
              text: `${this.translate.instant("CRM_Company_Sucess_Updated")}`,
              icon: "success",
              timer: 3000,
            }).then(() => {
              // this._router.navigate([`/register/${company.vat}/${company.id}`]);

              this.completed.companyInformation = true;
              this.stepper.selected.completed = true;
              this.stepper.next();
            });
          }
        });
    }
  }

  showTermsConditions(): void {
    this._dialog.open(DialogTermsConditionsComponent, {
      width: "900px",
      disableClose: true,
      data: {
        withLabel: true,
        label: "SC_TermsOfUse_Content",
      },
    });
  }

  showPrivacyPolicy(): void {
    this._dialog.open(DialogPrivacyPolicyComponent, {
      width: "980px",
      disableClose: true,
      data: {
        withLabel: true,
        label: "SC_Privacy_Content",
      },
    });
  }

  setValueToFirstUser(type: string): void {
    switch (type) {
      case "title":
        this.cardboardUsers.at(0).patchValue({
          title: this.ContactTitle.value,
        });

        break;

      case "name":
        this.cardboardUsers.at(0).patchValue({
          name: this.ContactName.value,
        });

        break;

      case "lastName":
        this.cardboardUsers.at(0).patchValue({
          lastName: this.ContactLastName.value,
        });

        break;

      case "email":
        this.cardboardUsers.at(0).patchValue({
          email: this.ContactEmail.value,
        });

        break;
    }
  }

  addUserToList(): void {
    const usersCount = this.cardboardUsers.length;

    if (usersCount < this.userAccountsLimit) {
      this.cardboardUsers.push(
        this.formBuilder.group({
          id: 0,
          name: ["", Validators.required],
          lastName: ["", Validators.required],
          title: ["", Validators.required],
          email: [
            "",
            [
              Validators.required,
              Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
            ],
          ],
        })
      );
    } else {
      Swal.fire({
        title: `${this.translate.instant("SCALE_AmountLimit")}`,
        text: `${this.translate.instant("SCALE_UsersLimitMessage", {
          value: this.userAccountsLimit,
        })}`,
        icon: "warning",
        timer: 4000,
      });
    }
  }

  removeCardboardUserElement(user: any, index: number) {
    if (!user) {
      return;
    }

    Swal.fire({
      title: `${this.translate.instant("General_Delete")}`,
      text: `${this.translate.instant("General_Delete_Confirmation", {
        value: this.translate.instant("General_User"),
      })}`,
      confirmButtonText: `${this.translate.instant("General_Confirm")}`,
      cancelButtonText: `${this.translate.instant("General_Cancel")}`,
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        if (user.id) {
          this.cardboardService
            .deleteUser({ vat: this.vat, userId: user.id })
            .subscribe((value: boolean) => {
              if (value) {
                this.cardboardUsers.removeAt(index);

                Swal.fire({
                  title: `${this.translate.instant("General_Deleted")}`,
                  text: `${this.translate.instant("General_Delete_Success")}`,
                  icon: "success",
                  timer: 3000,
                });
              }
            });
        } else {
          this.cardboardUsers.removeAt(index);

          Swal.fire({
            title: `${this.translate.instant("General_Deleted")}`,
            text: `${this.translate.instant("General_Delete_Success")}`,
            icon: "success",
            timer: 3000,
          });
        }
      }
    });
  }

  get cardboardUsers(): FormArray {
    return this.companyFormGroup.get("cardboardUsers") as FormArray;
  }

  addRepresentative(): void {
    if (this.cardboardRepresentatives.length < 2) {
      this.cardboardRepresentatives.push(
        this.formBuilder.group({
          name: ["", Validators.required],
          lastName: ["", Validators.required],
          title: ["", Validators.required],
          businessTitle: ["", Validators.required],
          dni: ["", Validators.required],
          dniValidation: [true, Validators.requiredTrue],
          email: [
            "",
            [
              Validators.required,
              Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
            ],
          ],
          phone: ["", Validators.required],
          countryPhone: ["+34", Validators.required],
        })
      );
    } else {
      Swal.fire({
        title: `${this.translate.instant("SCALE_AmountLimit")}`,
        html: `${this.translate.instant("SCALE_RepresentativesLimitMessage", {
          value: 2,
        })}`,
        icon: "warning",
      });
    }
  }

  removeRepresentative(index: number) {
    this.cardboardRepresentatives.removeAt(index);
  }

  get cardboardRepresentatives(): FormArray {
    return this.uploadContractFormGroup.get(
      "cardboardRepresentatives"
    ) as FormArray;
  }

  uploadContract(): void {
    const form = Object.assign({}, this.uploadContractFormGroup.value);
    form.file = this.file;

    if (this.file) {
      this.cardboardService
        .uploadDocumentToCompany(
          this.companyFormGroup.value.id,
          "trade_register_excerpt",
          this.file
        )
        .subscribe((value: any) => {
          if (value) {
            this.addRepresentatives();
          }
        });
    } else {
      this.addRepresentatives();
    }
  }

  addRepresentatives(): void {
    const form = Object.assign({}, this.uploadContractFormGroup.value);
    form.id = this.companyFormGroup.value.id;

    this.cardboardService
      .setRepresentatives(form)
      .subscribe((response: any) => {
        if (response) {
          Swal.fire({
            title: this.translate.instant("General_Saved"),
            text: this.translate.instant("General_AddRepresentativesMessage"),
            icon: "success",
            timer: 4000,
          });

          if (this.file && form.cardboardRepresentatives?.length > 0) {
            window.location.reload();
          }
        }
      });
  }

  downloadContract(): void {
    let url = `${environment.crmURL}/documents/mandato_modelo_576.pdf`;
    window.open(url, "_blank")?.focus();
  }

  handleFile(event: any): void {
    this.file = event.target.files[0];
  }

  downloadUploadedContract(): void {
    let url = `${environment.crmURL}/companies/${this.companyFormGroup.value.id}/${this.updateFile.fullName}`;
    window.open(url, "_blank")?.focus();
  }

  createNDA(): void {
    this.cardboardService
      .createCardboardNDA({ vat: this.vat })
      .subscribe((response: any) => {
        if (response) {
          Swal.fire({
            title: this.translate.instant("General_Contract"),
            text: this.translate.instant("General_CreateContractMessage"),
            icon: "success",
            timer: 4000,
          });

          if (response.documents) {
            const document = response.documents
              .filter(
                (document: any) =>
                  document.documentType === "trade_register_excerpt"
              )
              .at(-1);

            if (document) {
              this.tradeRegisterExcerpt = document;
              this.completed.cardboardRepresentatives = true;
            }
          }

          this.signature = response.signature;

          if (!this.completed.cardboardRepresentatives) {
            this.completed.cardboardRepresentatives = true;
            this.stepper.selected.completed = true;
            this.stepper.next();
          }
        }
      });
  }

  downloadTradeRegisterExcerpt(): void {
    let url = `${environment.cardboardURL}/cardboard/${this.companyFormGroup.value.id}/trade_register_excerpt/${this.tradeRegisterExcerpt?.fullName}`;
    window.open(url, "_blank")?.focus();
  }

  removeLegalFormFromCompanyName(): void {
    let company = this.companyFormGroup.value.name;

    if (!company) {
      return;
    }

    if (this.legalForms?.length < 1) {
      return;
    }

    let split = company.split(" ");

    if (split?.length < 1) {
      return;
    }

    const regexValue = /[.\s]/g;

    split
      .filter((value: any) => value)
      .map((value: string) => {
        const find = this.legalForms.filter((legalForm: any) =>
          legalForm.name
            .replace(regexValue, "")
            .toLowerCase()
            .includes(value.toLowerCase().replace(regexValue, ""))
        );

        if (find[0]) {
          company = company.replaceAll(value, "").trim();

          this.companyFormGroup.patchValue({
            name: company,
          });
        }
      });
  }

  getUploadedFiles(files: any): void {
    if (!files) {
      return;
    }

    this.file = files[0]?.file;
  }

  removeCharacters(event: any): void {
    this.vatduplicated = "";
    let value = event.target.value;

    if (!value) {
      return;
    }

    value = value.replace(/\D/g, "");

    if (event.srcElement.id == "vatInput") {
      this.companyFormGroup.patchValue({
        vat: value,
      });

      this.checkDuplicatedVAT();
    } else if (event.srcElement.id == "postalCodeInput") {
      this.companyFormGroup.patchValue({
        postalCode: value,
      });
    }
  }

  checkDuplicatedVAT(): void {
    this.cardboardService
      .checkDuplicatedVAT(this.companyFormGroup.value.vat)
      .subscribe((response: any) => {
        if (response) {
          this.vatduplicated = this.companyFormGroup.value.vat;

          this.companyFormGroup.patchValue({
            vat: "",
          });
        }
      });
  }

  checkDNI(representative: any): void {
    if (!representative || !representative.value.dni) {
      return;
    }

    this.cardboardService
      .validateDNI(representative.value.dni)
      .subscribe((response: any) => {
        if (response) {
          if (!response.type || !response.valid) {
            representative.patchValue({
              dniValidation: false,
            });

            return;
          }

          representative.patchValue({
            dniValidation: true,
          });
        }
      });
  }

  deleteRepresentative(index: number): void {
    this.cardboardRepresentatives.removeAt(index);
  }

  dniValidation(value: string, type: string): void {
    if (!value) {
      return;
    }

    this.cardboardService.validateDNI(value).subscribe((response: any) => {
      if (response) {
        let validation = true;

        if (!response.type || !response.valid) {
          validation = false;
        }

        switch (type) {
          case "VAT":
            this.companyFormGroup.patchValue({
              vatValidation: validation,
            });
            break;
        }
      }
    });
  }
}
