import { Component, OnInit, Inject } from "@angular/core";
import {
  Validators,
  FormArray,
  FormBuilder,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { IVatValidation } from "src/app/services/vat-validation/vat-validation";
import { ILegalForm } from "src/app/models/parameters/ILegalForm";
import { IPostalCode } from "src/app/models/parameters/IPostalCode";
import { ICity } from "src/app/models/parameters/ICity";
import { IState } from "src/app/models/parameters/IState";
import { IVatValidationObject } from "src/app/models/company/IVatValidationObject";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-add-representative",
  templateUrl: "./add-representative.component.html",
  styleUrls: ["./add-representative.component.scss"],
})
export class DialogAddRepresentativeComponent implements OnInit {
  public responseRepresentativeList: FormArray;
  public formGroup: FormGroup;
  public vatValidation: IVatValidationObject;

  public states: IState[];
  public cities: ICity[];
  private countries: any[];
  private registers: any[];
  private postalCodes: IPostalCode[];
  private legalForms: ILegalForm[];
  public onlyNaturalPerson: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogAddRepresentativeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private vatService: IVatValidation,
    private formBuilder: FormBuilder
  ) {}

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.initVat();

    if (this.data.representative) {
      const representative = this.data.representative;

      if (!representative.IsNaturalPerson) {
        this.formGroup = this.formBuilder.group({
          Index: [this.data.index],
          RepresentativeName: [
            !representative.IsNaturalPerson
              ? representative.Name
                ? representative.Name
                : representative.RepresentativeName
              : "",
          ],
          RepresentativeTitle: [
            !representative.IsNaturalPerson ? representative.Title : "",
          ],
          RepresentativeNationality: [
            !representative.RepresentativeNationality
              ? representative.Title
              : "",
          ],
          IsNaturalPerson: [representative.IsNaturalPerson],

          CompanyName: [
            !representative.IsNaturalPerson
              ? representative.Name
                ? representative.Name
                : representative.CompanyName
              : "",
          ],
          DNI: [representative.DNI],

          Address: this.formBuilder.group({
            Country: [
              representative.Address ? representative.Address.Country : "",
            ],
            State: [representative.Address ? representative.Address.State : ""],
            City: [representative.Address ? representative.Address.City : ""],
            PostalCode: [
              representative.Address ? representative.Address.PostalCode : "",
            ],
            StreetNumber: [
              representative.Address ? representative.Address.StreetNumber : "",
            ],
          }),
          LegalForm: [representative.LegalForm],
          Representatives: [
            representative.Representatives
              ? representative.Representatives
              : [],
          ],
        });

        this.getLegalFormsByCountry();
        this.getStatesByCountry();
        this.getCitiesByState();
        this.getPostalCodesByCity();
      } else {
        this.formGroup = this.formBuilder.group({
          Index: [this.data.index],
          RepresentativeName: [
            representative.Name
              ? representative.Name
              : representative.RepresentativeName,
          ],
          RepresentativeTitle: [
            representative.Title
              ? representative.Title
              : representative.RepresentativeTitle,
          ],
          RepresentativeNationality: [representative.RepresentativeNationality],
          IsNaturalPerson: [representative.IsNaturalPerson],
          CompanyName: [""],
          VAT: [""],
          Address: this.formBuilder.group({
            Country: [""],
            State: [""],
            City: [""],
            PostalCode: [""],
            StreetNumber: [""],
          }),
          LegalForm: [{}],
        });
      }
    } else {
      this.createFormBuilder();
    }
  }

  assignRepresentatives(list: FormArray) {
    const representativesNameList = list.value.map(
      (representative) => representative.RepresentativeName
    );

    const indexNullList = [];
    this.data.representative.value.map((representative, index) => {
      if (
        representative.RepresentativeName == null ||
        representativesNameList.includes(representative.RepresentativeName)
      ) {
        indexNullList.push(index);
      }
      if (representative.Representatives == null) {
        representative.Representatives = new FormArray([]);
      }
    });

    // Remove elements
    if (
      indexNullList.length > 0 &&
      this.data.representative.value.length > 0 &&
      indexNullList.length == this.data.representative.value.length
    ) {
      this.data.representative = new FormArray([]);
    }

    const representativesNames = this.data.representative.value.map(
      (r) => r.RepresentativeName
    );
    list.value.map((currentRepresentative) => {
      const currentFormGroup = this.formBuilder.group({
        RepresentativeName: [currentRepresentative.RepresentativeName],
        RepresentativeTitle: [currentRepresentative.RepresentativeTitle],
        RepresentativeNationality: [
          currentRepresentative.RepresentativeNationality,
        ],
        Address: [currentRepresentative.Address],
        IsNaturalPerson: [currentRepresentative.IsNaturalPerson],
        Representatives:
          currentRepresentative.Representatives === undefined
            ? new FormArray([])
            : new FormArray(
                currentRepresentative.Representatives.map(
                  (r) => new FormControl(r)
                )
              ),
        Documents: [
          currentRepresentative.Documents === undefined
            ? new FormArray([])
            : currentRepresentative.Documents,
        ],
        LegalForm: [currentRepresentative.LegalForm],
        Register: [currentRepresentative.Register],
        RegistrationAuthority: [currentRepresentative.RegistrationAuthority],
        RegistrationNumber: [currentRepresentative.RegistrationNumber],
        Notary: [currentRepresentative.Notary],
      });
      this.data.representative.push(currentFormGroup);
    });
    this.responseRepresentativeList = this.data.representative;
  }

  createFormBuilder() {
    this.formGroup = this.formBuilder.group({
      Index: [0],
      RepresentativeName: [""],
      RepresentativeTitle: [""],
      RepresentativeNationality: [""],
      IsNaturalPerson: [true],
      CompanyName: [""],
      VAT: [""],
      DNI: [""],
      Address: this.formBuilder.group({
        Country: ["", Validators.required],
        State: ["", Validators.required],
        City: ["", Validators.required],
        PostalCode: ["", Validators.required],
        StreetNumber: ["", Validators.required],
      }),
      LegalForm: [{}],
      Notary: this.formBuilder.group({
        State: ["", Validators.required],
        RegistrationDate: ["", Validators.required],
        Name: ["", Validators.required],
        RegistrationNumber: ["", Validators.required],
      }),
    });
  }

  initVat() {
    this.vatValidation = {
      valid: null,
      company_address: "",
      company_name: "",
      country_code: "",
      database: "",
      format_valid: false,
      query: "",
      vat_number: "",
    };
  }

  validateVatNumber() {
    const vat = this.formGroup.controls["VAT"].value; // LU26375245 - BE0433666709 - FR07433966132

    this.vatService.validateVAT(vat).subscribe((response) => {
      this.vatValidation = response;
    });
  }

  getLegalFormsByCountry() {
    const selectedCountry = this.formGroup.controls["Address"].value.Country;

    if (!selectedCountry) {
      return;
    }

    this.legalForms =
      this.data.countries.filter((c: any) => c.name === selectedCountry)[0]
        .legalForms || [];
  }

  getStatesByCountry() {
    const selectedCountry = this.formGroup.controls["Address"].value.Country;

    if (!selectedCountry) {
      return;
    }

    this.states =
      this.data.countries.filter((c: any) => c.name === selectedCountry)[0]
        .states || [];
  }

  getCitiesByState(): void {
    const state = this.formGroup.controls["Address"].value.State;

    if (!state) {
      return;
    }

    this.cities =
      this.states !== undefined
        ? this.states.filter((c) => c.name === state)[0].cities
        : [];
  }

  getPostalCodesByCity(): void {
    const city = this.formGroup.controls["Address"].value.City;

    if (!city) {
      return;
    }

    this.postalCodes =
      this.cities.filter((c) => c.name === city)[0].postalCodes || [];
  }

  get Representatives(): FormArray {
    return this.formGroup.get("Representatives") as FormArray;
  }
}
