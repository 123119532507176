import { environment } from "src/environments/environment";
import { ICardboardService } from "./cardboard.interface";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import Swal from "sweetalert2";
import { IHelperService } from "../helper/helper";
import { TranslateService } from "@ngx-translate/core";
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: "root",
})
export class CardboardService implements ICardboardService {
  constructor(
    private helperService: IHelperService,
    private translate: TranslateService,
    private http: HttpClient
  ) {}

  public getCardboardCompanyByVAT(VAT: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.cardboardURL}/api/cardboard/getCardboardCompanyByVAT?VAT=${VAT}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )} \n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public getCardboardCompanyBankDetailsByVAT(vat: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.cardboardURL}/api/cardboard/getCardboardCompanyBankDetailsByVAT?VAT=${vat}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            let id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )} \n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public getCardboardCompanyById(id: number): Observable<any> {
    return this.http
      .get<any>(
        `${environment.cardboardURL}/api/cardboard/getCardboardCompanyById?id=${id}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public companyRegistration(cardboardCompany: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.cardboardURL}/api/cardboard/companyRegistration`,
        cardboardCompany
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public updateCardboardCompany(cardboardCompany: any): Observable<any> {
    return this.http
      .put<any>(
        `${environment.cardboardURL}/api/cardboard/updateCardboardCompany`,
        cardboardCompany
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public setRepresentatives(representatives: any): Observable<any> {
    return this.http
      .put<any>(
        `${environment.cardboardURL}/api/cardboard/setRepresentativesToCompany`,
        representatives
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public uploadDocumentToCompany(
    companyId: number,
    documentType: string,
    file: any
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("fileDocumentType", documentType);
    formData.append("file", file, file.name);
    formData.append("id", companyId.toString());

    return this.http
      .post<any>(
        `${environment.cardboardURL}/api/cardboard/uploadDocumentToCompany`,
        formData
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      );
  }

  public createCardboardNDA(data: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.cardboardURL}/api/cardboard/createCardboardNDA`,
        data
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )} \n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public getCardboardUnsubscribeByEmail(email: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.cardboardURL}/api/cardboard/getCardboardUnsubscribeByEmail?email=${email}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public unsubscribeToEmails(email: string): Observable<any> {
    return this.http
      .post<any>(
        `${environment.cardboardURL}/api/cardboard/UnsubscribeToEmails`,
        {
          email,
        }
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public deleteUser(user: any): Observable<any> {
    return this.http
      .post<any>(`${environment.cardboardURL}/api/cardboard/deleteUser`, user)
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  saveSystemLogs(logs: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.cardboardURL}/api/systemLogs/saveSystemLogs`,
        logs
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          }
        })
      );
  }

  getCardboardCompanySignature(
    id: number,
    email: string,
    contractType: string
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.cardboardURL}/api/cardboard/getCardboardCompanySignature?id=${id}&email=${email}&contractType=${contractType}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            const id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );

            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  uploadSignedContract(form: any, file: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("id", form.id);
    formData.append("uniqueCode", form.uniqueCode);
    formData.append("contractType", form.contractType);
    formData.append("file", file, file.name);

    return this.http
      .post<any>(
        `${environment.cardboardURL}/api/cardboard/uploadSignedContract`,
        formData
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      );
  }

  getLegalFormsByCountryISO2(iso2: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.crmURL}/api/legalForms/getLegalFormsByCountryISO2?iso2=${iso2}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  getStatesByCountry(country: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.crmURL}/api/countries/getStatesByCountry?countryName=${country}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  checkDuplicatedVAT(vat: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.cardboardURL}/api/cardboard/checkDuplicatedVAT?vat=${vat}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  getCompanyInfo(vat: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.cardboardURL}/api/cardboard/getCompanyInfo?vat=${vat}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  validateDNI(dni: string): Observable<any> {
    return this.http
      .get<any>(`https://documentconverter.smartec.pro/validate/dni?dni=${dni}`)
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  saveBankInfoAccount(bankDetails: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.cardboardURL}/api/cardboard/saveBankInfoAccount`,
        bankDetails
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            let id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );

            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public getBankDetailsFromIban(iban: string): Observable<any> {
    return this.http
      .post<any>(`https://documentconverter.smartec.pro/iban/decode`, { iban })
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              html: `${response.errors.join(", ")}`,
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public getBanksByCountry(country: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.crmURL}/api/companies/getAllBanksByCountry?country=${country}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.errors[0]?.replace(
                "One or more errors occurred.",
                ""
              ),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  getRepresentativeTitlesByProject(projectId: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.crmURL}/api/projects/${projectId}/representativeTitles`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  getInvoicesByCompany(companyId: string): Observable<any> {
    return this.http
    .get<any>(
      `${environment.crmURL}/api/invoicePayments/search/${companyId}/project/63aabe251e00c4ecdb9da718`
    )
    .pipe(
      map((response: any) => {
        if (response.status === 200) {
          return response.data;
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.errors.join(", "),
          });
        }
      })
    )
    .pipe(catchError(this.helperService.errorHandler));
  }

  isApprovedInvoice(companyId: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.crmURL}/api/invoicePayments/is-approved/${companyId}/project/63aabe251e00c4ecdb9da718`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  deleteInvoice(invoice: any): Observable<any> {
    return this.http
      .post<any>(`${environment.crmURL}/api/invoicePayments/delete-invoice`, {
        invoiceId: invoice.id,
        companyId: invoice.cardboardCompanyId.toString(),
        reason: invoice.reason,
      })
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }
}
