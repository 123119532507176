import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";

@Component({
  selector: "smartec-file-upload",
  templateUrl: "./smartec-file-upload.component.html",
  styleUrls: ["./smartec-file-upload.component.scss"],
})
export class SmartecFileUploadComponent implements OnInit {
  @Input() message: string = "General_DnDMessage";
  @Input() limit: number = 1;

  // @ViewChild(MatPaginator, {static:false}) paginator: MatPaginator;
  @ViewChild("file", { read: ElementRef }) file: ElementRef;

  @Output() files: EventEmitter<any> = new EventEmitter();

  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = ["name", "actions"];
  public uploadedFiles: any[] = [];

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}

  onFileChange(files: File[]) {
    if (this.limit > 0 && this.uploadedFiles.length >= this.limit) {
      Swal.fire({
        title: this.translateService.instant("General_Error"),
        text: this.translateService.instant("General_UnableToUploadMoreFiles"),
        icon: "warning",
      });

      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (this.limit > 0 && this.uploadedFiles.length >= this.limit) {
        Swal.fire({
          title: this.translateService.instant("General_Error"),
          text: this.translateService.instant(
            "General_UnableToUploadMoreFiles"
          ),
          icon: "warning",
        });

        return;
      }

      const currentFile = files[i];

      if (!this.fileValidation(currentFile)) {
        return;
      }

      const duplicated =
        this.uploadedFiles.filter((file: any) => file.name === currentFile.name)
          .length > 0;

      if (duplicated) {
        Swal.fire({
          title: this.translateService.instant("General_Duplicated"),
          text: this.translateService.instant("General_DuplicatedFileUploaded"),
          icon: "warning",
        });

        return;
      }

      let uploadFile = {
        name: currentFile.name,
        file: currentFile,
      };

      this.uploadedFiles.push(uploadFile);
      this.files.emit(this.uploadedFiles);
      this.dataSource = new MatTableDataSource(this.uploadedFiles);
      // this.dataSource.paginator = this.paginator;
    }
  }

  public fileValidation(file: File) {
    return true;
  }

  removeFile(index: number): void {
    this.uploadedFiles.splice(index, 1);

    this.files.emit(this.uploadedFiles);
    this.dataSource = new MatTableDataSource(this.uploadedFiles);
    // this.dataSource.paginator = this.paginator;
  }
}
