import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { throwError } from 'rxjs';
import { IVatValidationObject } from 'src/app/models/company/IVatValidationObject';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: 'root'
})
export class VatValidationService {

  private _accessKey:String = 'a881571d0f02e4127b2dda15b88e042f';
  private _format:String = '1';

  constructor(
      private http: HttpClient,
      private _translateService : TranslateService
      ) { }

  validateVAT(vatNumber:String) {
      return this.http.get<IVatValidationObject>(`http://www.apilayer.net/api/validate?access_key=${this._accessKey}&vat_number=${vatNumber}&format=${this._format}`).pipe(
          retry(0),
          catchError(this.handleError)
      )
  }

  // Error handling 
  handleError(error: any) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        Sentry.captureException(error.error);
          // Get client-side error
          errorMessage = error.error.message;
      } else {
        var id = Sentry.captureException(error);
        // Get server-side error
        errorMessage = `Error: ${error.status}\nTrace ID: ${id}\nMessage: ${error.message}`;
      }

      Swal.fire({
          title:  `${this._translateService.instant('General_Error')}`,
          text: errorMessage,
          icon: 'error'
      });
      
      return throwError(errorMessage);
  }
}
