import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ICardboardService } from "src/app/services/cardboard/cardboard.interface";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-display-contract-signed",
  templateUrl: "./display-contract-signed.component.html",
  styleUrls: ["./display-contract-signed.component.scss"],
})
export class DisplayContractSignedComponent implements OnInit {
  public vat: string = "";
  public company: any;

  constructor(
    private cardboardService: ICardboardService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe((params: any) => {
      this.vat = params.vat;
    });
  }

  ngOnInit(): void {
    this.getCompanyInfo();
  }

  getCompanyInfo(): void {
    this.cardboardService
      .getCompanyInfo(this.vat)
      .subscribe((response: any) => {
        this.company = response;
      });
  }

  displayPDF(): void {
    if (!this.company?.contract) {
      return;
    }

    window.open(
      `${environment.cardboardURL}${this.company.contract}`,
      "_blank"
    );
  }
}
