import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-delete-invoices",
  templateUrl: "./delete-invoices.component.html",
  styleUrls: ["./delete-invoices.component.scss"],
})
export class DeleteInvoicesComponent implements OnInit {
  public formGroup: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    this.formGroup = this.formBuilder.group({
      reason: ["", Validators.required],
    });
  }

  ngOnInit(): void {}
}
