import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ibanValidator } from "src/app/validators/validators.iban";
import { bicValidator } from "src/app/validators/validator.bic";
import { Component, OnInit } from "@angular/core";
import { ICardboardService } from "src/app/services/cardboard/cardboard.interface";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { DeleteInvoicesComponent } from "../../dialogs/delete-invoices/delete-invoices.component";

@Component({
  selector: "app-register-payment",
  templateUrl: "./register-payment.component.html",
  styleUrls: ["./register-payment.component.scss"],
})
export class RegisterPaymentComponent implements OnInit {
  public formGroupBankAccount: FormGroup;
  public company: any = {};
  public disabled: boolean = false;
  public bankList: any[] = [];
  public isManualBank: boolean = false;
  public currentYear = new Date().getFullYear();
  public cardboardURL: string = environment.cardboardURL;
  public invoices: any[] = [];

  constructor(
    private cardboardService: ICardboardService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.formGroupBankAccount = this.formBuilder.group({
      id: [undefined, Validators.required],
      hasEuVat: [false, Validators.requiredTrue],
      euVat: "",
      vat: ["", Validators.required],
      bankHolder: ["", Validators.required],
      financialEntity: ["", Validators.required],
      iban: ["", [Validators.required, ibanValidator]],
      bic: ["", [Validators.required, bicValidator]],
      invoice: undefined,
      invoiceFileName: "",
    });

    this.activatedRoute.params.subscribe((params: any) => {
      this.formGroupBankAccount.patchValue({
        vat: params.vat,
      });

      this.getCompanyByVAT(params.vat);
    });
  }

  ngOnInit(): void {
    this.getBankList();
  }

  getBankList(): void {
    this.cardboardService.getBanksByCountry("es").subscribe((value: any) => {
      this.bankList = value;
    });
  }

  getCompanyByVAT(vat: string): void {
    this.cardboardService
      .getCardboardCompanyBankDetailsByVAT(vat)
      .subscribe((response: any) => {
        this.company = response;

        this.cardboardService
          .getInvoicesByCompany(this.company.id)
          .subscribe((invoices: any) => {
            this.invoices = invoices;
          });

        this.formGroupBankAccount.patchValue({
          id: response.id,
          hasEuVat: response.hasEuVat,
        });

        if (response.euVat) {
          this.formGroupBankAccount.patchValue({
            euVat: response.euVat,
          });
        }

        if (response.bankHolder) {
          this.formGroupBankAccount.patchValue({
            bankHolder: response.bankHolder,
          });
        } else {
          this.formGroupBankAccount.patchValue({
            bankHolder: response.name,
          });
        }

        if (response.financialEntity) {
          this.formGroupBankAccount.patchValue({
            financialEntity: response.financialEntity,
          });
        }

        if (response.iban) {
          this.formGroupBankAccount.patchValue({
            iban: response.iban,
          });
        }

        if (response.bic) {
          this.formGroupBankAccount.patchValue({
            bic: response.bic,
          });
        }

        if (this.invoices?.length > 0 && this.company?.id) {
          this.cardboardService
            .isApprovedInvoice(this.company.id)
            .subscribe((invoice_response: any) => {
              this.disabled = invoice_response;

              if (this.disabled) {
                this.formGroupBankAccount
                  .get("hasEuVat")
                  .disable({ onlySelf: true });
                this.formGroupBankAccount
                  .get("euVat")
                  .disable({ onlySelf: true });
                this.formGroupBankAccount
                  .get("vat")
                  .disable({ onlySelf: true });
                this.formGroupBankAccount
                  .get("bankHolder")
                  .disable({ onlySelf: true });
                this.formGroupBankAccount
                  .get("financialEntity")
                  .disable({ onlySelf: true });
                this.formGroupBankAccount
                  .get("iban")
                  .disable({ onlySelf: true });
                this.formGroupBankAccount
                  .get("bic")
                  .disable({ onlySelf: true });
              }
            });
        }

        // const value = this.formGroupBankAccount.value;

        // if (
        //   value.vat &&
        //   value.bankHolder &&
        //   value.financialEntity &&
        //   value.iban &&
        //   value.bic
        // ) {
        //   this.formGroupBankAccount.get("euVat").disable({ onlySelf: true });
        //   this.formGroupBankAccount.get("vat").disable({ onlySelf: true });
        //   this.formGroupBankAccount
        //     .get("bankHolder")
        //     .disable({ onlySelf: true });
        //   this.formGroupBankAccount
        //     .get("financialEntity")
        //     .disable({ onlySelf: true });
        //   this.formGroupBankAccount.get("iban").disable({ onlySelf: true });
        //   this.formGroupBankAccount.get("bic").disable({ onlySelf: true });

        //   if (this.company.invoice) {
        //     this.disabled = true;
        //   }
        // }
      });
  }

  saveBankInfoAccount(): void {
    if (this.formGroupBankAccount.invalid) {
      return;
    }

    const bankDetails = Object.assign(
      {},
      this.formGroupBankAccount.getRawValue()
    );

    this.cardboardService
      .saveBankInfoAccount(bankDetails)
      .subscribe((response: any) => {
        if (response) {
          Swal.fire({
            title: this.translateService.instant("General_Saved"),
            text: this.translateService.instant("General_BankDetailsSaved"),
            icon: "success",
            timer: 3000,
          });

          window.location.reload();
        }
      });
  }

  onFileChange(event: any): void {
    const reader = new FileReader();

    if (event) {
      const file = event;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.formGroupBankAccount.patchValue({
          invoice: reader.result,
          invoiceFileName: file.name,
        });
      };
    }
  }

  downloadInvoice(): void {
    let url = `${environment.cardboardURL}${this.company.invoice}`;
    window.open(url, "_blank")?.focus();
  }

  getBankDetailsFromIban(): void {
    const iban = this.formGroupBankAccount.value.iban;

    if (!iban) {
      return;
    }

    this.cardboardService
      .getBankDetailsFromIban(iban)
      .subscribe((response: any) => {
        if (response) {
          if (response.bic) {
            const bank = this.bankList.find(
              (bank: any) => bank.bic === response.bic
            );

            if (bank) {
              this.formGroupBankAccount.patchValue({
                financialEntity: bank.name,
              });
            }

            this.formGroupBankAccount.patchValue({
              bic: response.bic,
            });
          }
        }
      });
  }

  setBic(value: any): void {
    if (value) {
      this.formGroupBankAccount.patchValue({
        bic: value.bic,
      });
    }
  }

  deleteInvoice(invoice: any, index: number): void {
    Swal.fire({
      title: `${this.translateService.instant("General_Delete")}`,
      text: `${this.translateService.instant("General_Delete_Confirmation", {
        value: this.translateService.instant("General_Invoice")?.toLowerCase(),
      })}`,
      confirmButtonText: `${this.translateService.instant("General_Confirm")}`,
      cancelButtonText: `${this.translateService.instant("General_Cancel")}`,
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        const dialogRef = this.dialog.open(DeleteInvoicesComponent, {
          width: "800px",
          disableClose: true,
          data: {
            invoice,
          },
        });

        dialogRef.afterClosed().subscribe((closed: any) => {
          if (closed) {
            this.cardboardService
              .deleteInvoice({
                id: invoice.id,
                cardboardCompanyId: invoice.companyId,
                reason: closed.reason,
              })
              .subscribe((response: any) => {
                if (response) {
                  this.invoices.splice(index, 1);

                  //TODO: Que hacer con el documentId? Eliminarlo tambien de la lista de Documentos de la empresa?
                }
              });
          }
        });
      }
    });
  }
}
