import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";
import { IHelperService } from "./helper";
import { IUserRegistration } from "src/app/models/users/IUserRegistration";
import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: "root",
})
export class HelperService implements IHelperService {
  constructor() {}

  generateObjectId = (): string => {
    const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);

    return (
      timestamp +
      "xxxxxxxxxxxxxxxx"
        .replace(/[x]/g, () => {
          return ((Math.random() * 16) | 0).toString(16);
        })
        .toLowerCase()
    );
  };

  encryptUser(user: IUserRegistration) {
    const key = CryptoJS.enc.Utf8.parse(user.password).toString();

    const jsonString = JSON.stringify(user);

    const encrypted = CryptoJS.AES.encrypt(jsonString, key, {
      padding: CryptoJS.pad.NoPadding,
    }).toString();

    return encrypted;
  }

  errorHandler(httpError: HttpErrorResponse) {
    console.info('🔥 Please check the helper service');
    

    if (httpError.error instanceof ErrorEvent) {
      Sentry.captureException(httpError.error);
      if (httpError?.error?.message) {
        // this.toastr.error(`An error occurred ${httpError?.error?.message}`, 'Error');
        console.error("An error occurred:", httpError?.error?.message);
      }
    } else {
      if (httpError?.error) {
        Sentry.captureException(httpError.error);
        // this.toastr.error(`Backend returned code ${httpError?.status}, ` + `body was: ${httpError?.error}`, 'Error');
        console.error(
          `Backend returned code ${httpError?.status}, ` +
            `body was: ${httpError?.error}`
        );
      } else if (httpError["errors"]) {
        // this.toastr.error(`${httpError['errors']}`, 'Error');
        Sentry.captureException(httpError["errors"]);
        console.error(
          `Backend returned code ${httpError?.status}, ` +
            `body was: ${httpError["errors"]}`
        );
        return;
      }
    }
    Sentry.captureException(httpError);
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  };
}
