import { Injectable } from '@angular/core';
import { IPrivacyPolicyService } from './privacy-policy';
import { HttpClient } from '@angular/common/http';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { IPrivacyPolicy } from 'src/app/models/parameters/IPrivacyPolicy';
import { environment } from 'src/environments/environment';
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: 'root'
})
export class PrivacyPolicyService extends IPrivacyPolicyService {
  private apiURL = `${environment.crmURL}/api/privacyPolicies/`;

  constructor(
    private http: HttpClient,
    // private paginationService: PaginationService,
    private translateService: TranslateService
  ) {
    super();
  }

  getPrivacyPolicies() {
    return this.http.get<IPrivacyPolicy[]>(`${this.apiURL}getPrivacyPolicies`).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };

  getPrivacyPoliciesByLanguage(language: String = 'en') {
    language = this.translateService.currentLang;

    return this.http.get<IPrivacyPolicy>(`${this.apiURL}getPrivacyPolicyByLanguage?language=${language}`).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
      Sentry.captureException(error.error);
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      Sentry.captureEvent(error);
    }

    Swal.fire({
      title: `${this.translateService.instant('General_Error')}`,
      text: errorMessage,
      icon: 'error'
    });

    return throwError(errorMessage);
  }
}
