import { environment } from "src/environments/environment";
import { throwError, from, pipe, Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { ICountryService } from "./country";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import Swal from "sweetalert2";
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: "root",
})
export class CountryService implements ICountryService {
  private apiURL = `${environment.crmURL}/api/countries/`;

  constructor(private http: HttpClient, private translate: TranslateService) {}

  private countries: Observable<any>;

  getCountries() {
    if (!this.countries) {
      this.countries = this.http
        .get<any>(`${environment.crmURL}/api/countries/getAllCountries`)
        .pipe(
          map((response: any) => {
            if (response.status === 200) {
              return response.data;
            } else {
              var id = Sentry.captureMessage(`Server request failed with ${response.status}`);
              Swal.fire({
                icon: "error",
                title: "Error",
                text: response.errors.join(", "),
              });
            }
          })
        );
    }

    return this.countries;
  }

  getAllNationalities() {
    return this.http
      .get<any>(`${environment.crmURL}/api/countries/getAllNationalities`)
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(`Server request failed with ${response.status}`);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.errors.join(", "),
            });
          }
        })
      );
  }

  getCountriesDropdown(): Observable<any> {
    return this.http
      .get<any>(`${environment.crmURL}/api/countries/getCountriesDropdown`)
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(`Server request failed with ${response.status}`);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.errors.join(", "),
            });
          }
        })
      );
  }

  getCountriesDropdownWithLegalForms(): Observable<any> {
    return this.http
      .get<any>(`${environment.crmURL}/api/countries/getCountriesDropdownWithLegalForms`)
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(`Server request failed with ${response.status}`);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.errors.join(", "),
            });
          }
        })
      );
  }

  handleError(error: any) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      Sentry.captureException(error.error);
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      Sentry.captureException(error);
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    Swal.fire({
      title: `${this.translate.instant("General_Error")}`,
      text: errorMessage,
      icon: "error",
    });

    return throwError(errorMessage);
  }
}
