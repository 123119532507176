<div id="title" class="section-wrapper bg-image" style="background-image: linear-gradient(90deg, #4785ec 0%, #3366c1 100%)">
    <div class="get-started-area padding-top-110 pb-5 mb-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="section-title white brand desktop-center padding-bottom-50">
                        <h3 class="title" [innerHTML]="'SC_Services_Title' | translate">

                        </h3>

                        <p class="text-justify" [innerHTML]="'SC_Services_Subtitle' | translate">

                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="work-single-item-02">
                        <div class="icon">
                            <div class="shape" style="background-image: url(/assets/images/pattern.png);"></div>
                            <!-- <div class="shape-02"></div> -->
                            <fa-icon icon="users"></fa-icon>

                        </div>

                        <div class="content">
                            <h3 class="title" [innerHTML]="'SC_Services_Contract' | translate">
                            </h3>

                            <p class="text-justify" [innerHTML]="'SC_Services_Contract_Info' | translate">
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="work-single-item-02">
                        <div class="icon">
                            <div class="shape" style="background-image: url(/assets/images/pattern.png);"></div>
                            <!-- <div class="shape-02"></div> -->
                            <fa-icon icon="money-bill"></fa-icon>
                        </div>

                        <div class="content">
                            <h3 class="title" [innerHTML]="'SC_Services_Tag' | translate ">

                            </h3>

                            <p class="text-justify" [innerHTML]="'SC_Services_Tag_Info' | translate">
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="work-single-item-02">
                        <div class="icon">
                            <div class="shape" style="background-image: url(/assets/images/pattern.png);"></div>
                            <!-- <div class="shape-02"></div> -->
                            <fa-icon icon="gavel"></fa-icon>
                        </div>

                        <div class="content">
                            <h3 class="title" [innerHTML]="'SC_Services_Diagram' | translate">
                            </h3>

                            <p class="text-justify" [innerHTML]="'SC_Services_Diagram_Info' | translate ">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-12">
              <button type="button" class="callToAction" [routerLink]="['/register']" data-cy="landing-cta">
                {{'SC_Services_CallToAction' | translate}}
              </button>
            </div>
          </div>
        </div>
    </div>
</div>
