import { Component, Input, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-representative-has-signed",
  templateUrl: "./representative-has-signed.component.html",
  styleUrls: ["./representative-has-signed.component.scss"],
})
export class RepresentativeHasSignedComponent implements OnInit {
  @Input() representative: any;
  @Input() contractType: string;

  constructor() {}

  ngOnInit(): void {}

  downloadFile(file: any): void {
    let url = `${environment.crmURL}/cardboard/${this.representative.id}/representatives/${file.filename}`;
    window.open(url, "_blank")?.focus();
  }

  downloadCPA(fileUrl: string): void {
    let url = `${environment.cardboardURL}${fileUrl}`;
    window.open(url, "_blank")?.focus();
  }
}
