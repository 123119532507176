import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IVatValidation } from "src/app/services/vat-validation/vat-validation";
import { ILegalForm } from "src/app/models/parameters/ILegalForm";
import { IPostalCode } from "src/app/models/parameters/IPostalCode";
import { IState } from "src/app/models/parameters/IState";
import { ICity } from "src/app/models/parameters/ICity";
import { IVatValidationObject } from "src/app/models/company/IVatValidationObject";

@Component({
  selector: "dialog-representative",
  templateUrl: "./modal-representative.component.html",
  styleUrls: ["./modal-representative.component.scss"],
})
export class ModalRepresentativeComponent implements OnInit {
  public formGroup: FormGroup;

  private states: IState[];
  private cities: ICity[];
  private Countries: any[];
  private Registers: any[];
  private postalCodes: IPostalCode[];
  private legalForms: ILegalForm[];
  public VatValidation: IVatValidationObject;

  constructor(
    public dialogRef: MatDialogRef<ModalRepresentativeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _vatService: IVatValidation,
    private _formBuilder: FormBuilder
  ) {}

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.initVat();

    if (this.data.representative) {
      const representative = this.data.representative;

      if (!representative.IsNaturalPerson) {
        this.formGroup = this._formBuilder.group({
          Index: [this.data.index],
          RepresentativeName: [
            !representative.IsNaturalPerson
              ? representative.Name
                ? representative.Name
                : representative.RepresentativeName
              : "",
          ],
          RepresentativeTitle: [
            !representative.IsNaturalPerson ? representative.Title : "",
          ],
          IsNaturalPerson: [representative.IsNaturalPerson],

          CompanyName: [
            !representative.IsNaturalPerson
              ? representative.Name
                ? representative.Name
                : representative.CompanyName
              : "",
          ],
          VAT: [representative.VAT],

          Address: this._formBuilder.group({
            Country: [
              representative.Address ? representative.Address.Country : "",
            ],
            State: [representative.Address ? representative.Address.State : ""],
            City: [representative.Address ? representative.Address.City : ""],
            PostalCode: [
              representative.Address ? representative.Address.PostalCode : "",
            ],
            StreetNumber: [
              representative.Address ? representative.Address.StreetNumber : "",
            ],
          }),
          LegalForm: [representative.LegalForm],
        });

        this.getLegalFormsByCountry();
        this.getStatesByCountry();
        this.getCitiesByState();
        this.getPostalCodesByCity();
      } else {
        this.formGroup = this._formBuilder.group({
          Index: [this.data.index],
          RepresentativeName: [
            representative.Name
              ? representative.Name
              : representative.RepresentativeName,
          ],
          RepresentativeTitle: [
            representative.Title
              ? representative.Title
              : representative.RepresentativeTitle,
          ],
          IsNaturalPerson: [representative.IsNaturalPerson],
          CompanyName: [""],
          VAT: [""],
          Address: this._formBuilder.group({
            Country: [""],
            State: [""],
            City: [""],
            PostalCode: [""],
            StreetNumber: [""],
          }),
          LegalForm: [{}],
        });
      }
    } else {
      this.createFormBuilder();
    }
  }

  createFormBuilder() {
    this.formGroup = this._formBuilder.group({
      Index: [0],
      RepresentativeName: [""],
      RepresentativeTitle: [""],
      IsNaturalPerson: [true],
      CompanyName: [""],
      VAT: [""],
      Address: this._formBuilder.group({
        Country: ["", Validators.required],
        State: ["", Validators.required],
        City: ["", Validators.required],
        PostalCode: ["", Validators.required],
        StreetNumber: ["", Validators.required],
      }),
      LegalForm: [{}],
    });
  }

  initVat() {
    this.VatValidation = {
      valid: null,
      company_address: "",
      company_name: "",
      country_code: "",
      database: "",
      format_valid: false,
      query: "",
      vat_number: "",
    };
  }

  validateVatNumber() {
    const vat = this.formGroup.controls["VAT"].value; // LU26375245 - BE0433666709 - FR07433966132

    this._vatService.validateVAT(vat).subscribe((response) => {
      this.VatValidation = response;
    });
  }

  getLegalFormsByCountry() {
    const selectedCountry = this.formGroup.controls["Address"].value.Country;

    if (!selectedCountry) {
      return;
    }

    this.legalForms =
      this.data.countries.filter((c) => c.name === selectedCountry)[0]
        .legalForms || [];
  }

  getStatesByCountry() {
    const selectedCountry = this.formGroup.controls["Address"].value.Country;

    if (!selectedCountry) {
      return;
    }

    this.states =
      this.data.countries.filter((c) => c.name === selectedCountry)[0].states ||
      [];
  }

  getCitiesByState(): void {
    const state = this.formGroup.controls["Address"].value.State;

    if (!state) {
      return;
    }

    this.cities =
      this.states !== undefined
        ? this.states.filter((c) => c.name === state)[0].cities
        : [];
  }

  getPostalCodesByCity(): void {
    const city = this.formGroup.controls["Address"].value.City;

    if (!city) {
      return;
    }

    this.postalCodes =
      this.cities.filter((c) => c.name === city)[0].postalCodes || [];
  }
}
