<app-header></app-header>
<section class="container-fluid" style="margin-top: 9rem;">
    <div class="row justify-content-center">
        <div class="col-lg-8">
            <div class="section-title desktop-center padding-bottom-40">
                <h3 class="title">
                    {{'General_TermsConditions' | translate}}
                </h3>

                <p class="text-justify">
                    <span [innerHTML]="'SC_TermsOfUse_Content' | translate"></span>
                </p>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>