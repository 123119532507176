import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { PaginationService } from "../pagination/pagination.service";
import { throwError, Observable } from "rxjs";
import { catchError, retry, map } from "rxjs/operators";
import { ICompanyService } from "./company";
import { ICompany } from "src/app/models/company/ICompany";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class CompanyService implements ICompanyService {
  constructor(
    private http: HttpClient,
    private paginationService: PaginationService,
    private translate: TranslateService
  ) {}

  getCompanies() {
    return this.http
      .get<ICompany[]>(
        `${environment.crmURL}/api/companies/getCompanies?page=${this.paginationService.page}&pageCount=${this.paginationService.pageCount}`
      )
      .pipe(retry(0), catchError(this.handleError));
  }

  getCompanyById(id: string): Observable<ICompany> {
    return this.http
      .get<ICompany>(
        `${environment.crmURL}/api/companies/getCompanyById?id=${id}`
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getSimpleCompanyById(id: string): Observable<ICompany> {
    return this.http
      .get<any>(
        `${environment.crmURL}/api/companies/getSimpleCompanyById?id=${id}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: response.errors.join(", "),
            });
          }
        })
      );
  }

  getCompanyByVAT(vat: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.crmURL}/api/companies/getCompanyByVAT?vat=${vat}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: response.errors.join(", "),
            });
          }
        })
      );
  }

  getCompanyByVATAndContactEmail(form: any): Observable<any> {
    return this.http
      .get<any>(
        `${environment.crmURL}/api/companies/getCompanyByVATAndContactEmail?vat=${form.vat}&email=${form.email}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: response.errors.join(", "),
            });
          }
        })
      );
  }

  saveCompany(company: ICompany): Observable<any> {
    return this.http
      .post<any>(`${environment.crmURL}/api/companies/SaveCompany`, company)
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: response.errors.join(", "),
            });
          }
        })
      );
  }

  saveCompanyDecrypted(company: ICompany): Observable<any> {
    return this.http
      .post<any>(
        `${environment.crmURL}/api/companies/userCompanyRegistrationDecrypted`,
        company
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: response.errors.join(", "),
            });
          }
        })
      );
  }

  saveCompanyRegistration(company: ICompany): Observable<any> {
    return this.http
      .post<any>(
        `${environment.crmURL}/api/companies/saveCompanyRegistration`,
        company
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: response.errors.join(", "),
            });
          }
        })
      );
  }

  saveCompanyLSCRegistration(company: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.crmURL}/api/companies/saveCompanyLSCRegistration`,
        company
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: response.errors.join(", "),
            });
          }
        })
      );
  }

  companyRegistration(company: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.crmURL}/api/companies/companyRegistration`,
        company
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: response.errors.join(", "),
            });
          }
        })
      );
  }

  updateCompanyRegistration(company: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.crmURL}/api/companies/updateCompanyRegistration`,
        company
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: response.errors.join(", "),
            });
          }
        })
      );
  }

  userCompanyRegistration(company: ICompany): Observable<any> {
    return this.http
      .post<any>(
        `${environment.crmURL}/api/companies/userCompanyRegistration`,
        company
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: response.errors.join(", "),
            });
          }
        })
      );
  }

  updateCompany(company: ICompany): Observable<any> {
    return this.http
      .post<any>(`${environment.crmURL}/api/companies/UpdateCompany`, company)
      .pipe(retry(0), catchError(this.handleError));
  }

  deleteCompany(id: string): Observable<any> {
    const company = { UpdateId: id };

    return this.http
      .post<any>(`${environment.crmURL}/api/companies/DeleteCompany`, company)
      .pipe(retry(0), catchError(this.handleError));
  }

  uploadFileToCompany(
    companyId: string,
    documentType: string,
    file: any
  ): Observable<any> {
    const formData: FormData = new FormData();

    formData.append(
      "fileDateName",
      `${moment().format("YYYY_MM_DD_HH_mm_ss")}_${file.name}`
    );
    formData.append("fileDocumentType", documentType);
    formData.append("file", file, file.name);
    formData.append("id", companyId);

    return this.http
      .post<any>(
        `${environment.crmURL}/api/companies/uploadFilesToCompany`,
        formData
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: response.errors.join(", "),
            });
          }
        })
      );
  }

  companyEmailValidation(
    token: string,
    randomKey: string,
    companyId: string
  ): Observable<any> {
    return this.http
      .post<any>(
        `${environment.crmURL}/api/companies/CompanyEmailValidation`,
        {
          CompanyId: companyId,
          Token: token,
          RandomKey: randomKey,
        }
      )
      .pipe(retry(0), catchError(this.handleError));
  }

  companyCount(): Observable<any> {
    return this.http
      .get<any>(`${environment.crmURL}/api/companies/companyCount`)
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: response.errors.join(", "),
            });
          }
        })
      );
  }
  updateDocumentsCompany(documentsCompany: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.crmURL}/api/companies/UpdateDocumentsCompany`,
        documentsCompany
      )
      .pipe(retry(0), catchError(this.handleError));
  }

  addRepresentatives(data: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.crmURL}/api/companies/addRepresentatives`,
        data
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              title: `${this.translate.instant("General_Error")}`,
              text: response.errors.join(", "),
              icon: "error",
            });
          }
        })
      );
  }

  createCardboardNDA(data: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.crmURL}/api/companies/createCardboardNDA`,
        data
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              title: `${this.translate.instant("General_Error")}`,
              text: response.errors.join(", "),
              icon: "error",
            });
          }
        })
      );
  }

  handleError(error: any) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    Swal.fire({
      title: `${this.translate.instant("General_Error")}`,
      text: errorMessage,
      icon: "error",
    });

    return throwError(errorMessage);
  }
}
